<template>
    
    <div class="h-auto w-full flex flex-col">
        <loader :loading="loading" />
        <div class="h-auto p-2">
            <div v-if="tipsAndDocs" class="h-auto w-full rounded-lg module p-2 flex flex-col">
                <div class="h-auto py-1">
                    <span class="text-xs font-semibold" style="color: #13F2E3">Tips and docs</span>
                </div>
                <div class="h-6 rounded-lg bg-body my-2">
                    <input type="text" placeholder="Search" v-model="searchInput"
                        class="w-full h-6 text-xs rounded bg-body flex flex-row justify-start items-center px-2">
                </div>
                <div class="flex mt-1 mb-2 items-center">
                    <div class="header-btn-gray ml-3 relative" v-for="(doc, index) in typeDocs"
                         :key="index" :class="{ [doc.class]: selectedExtensions === doc.value }"
                         @click="filterByExtensions(doc.value)" >
                        <i :class="'text-xl mdi mdi-' + doc.name"></i>
                    </div>
                </div>
                <div v-if="el.file && el.file.length > 0" class="h-auto rounded-lg mt-1 flex flex-col"
                     v-for="(el, index) in search(filteredTipsAndDocs)" :key="index">

                    <div  class="box h-12 flex items-center p-4">
                        <div class="flex-none rounded h-8 w-8 flex items-center justify-center text-center cursor-pointer"
                             :class="getClassByExtension(el.file)" @click="openFile(el.file, 'file')">
                            <i class="mdi text-2xl" :class="getIconByExtension(el.file)"></i>
                        </div>
                        <div class="flex-1 min-w-0">
                            <p class="text-xs text-font-light font-medium px-4 truncate-2 leading-normal">{{ el.title }}</p>
                        </div>
                        <div class="text-font-gray self-start" style="font-size: 0.50rem;">
                            <p>{{ el.date | moment('MM/DD/YYYY') }}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>

import {state} from '@/store';

export default {
    data(){
        return {
            tipsAndDocs: null,
            loading: false,
            selectedExtensions: [],
            filteredType: null,
            typeDocs: this.$typeDocs,
            searchInput: '',
        }
    },
    methods: {
        load() {
            this.loading = true;
            this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=TipAndDoc&sorts[date]=DESC&filters[active]=1', {params: state.globalParams})
                .then(response => {
                    this.tipsAndDocs = response.data;
                    this.loading = false;
                })
        },
        filterByExtensions(extensions) {
            if (extensions === this.selectedExtensions) this.selectedExtensions = []
            else this.selectedExtensions = extensions
        },
        search(data){
            let parseArray = JSON.parse(JSON.stringify(data))
            return this.$search(parseArray, this.searchInput);
        },
        getIconByExtension(file) {
            let result = ''
            result += this.$iconByExtension(file)
            return result
        },
        getClassByExtension(file) {
            let result = ''
            result += this.$colorByExtension(file)
            return result
        },
        openFile(file, type) { this.$openFile(file, type) },
        goto(router) { this.$router.push({ name: router }) }
    },
    computed: {
        filteredTipsAndDocs() {
            if (this.selectedExtensions.length === 0) { return this.tipsAndDocs }
            return this.tipsAndDocs.filter(({ file }) => {
                const pathProps = file.split('.');
                const extension = pathProps[pathProps.length - 1];
                return this.selectedExtensions.includes(extension);
            });
        }
    },
    mounted() { this.load() }
}
</script>